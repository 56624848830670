import { createRouter, createWebHistory } from "vue-router";
import store from "./store/index.js";
import NProgress from "nprogress";
import "./assets/sass/nprogress.scss";

import Home from "./pages/Home.vue";
import NotFound from "./pages/NotFound.vue";
const Design = () => import("./pages/Design.vue");
const DesignDetail = () => import("./pages/DesignDetail.vue");
const Paintings = () => import("./pages/Paintings.vue");
const PaintingsDetail = () => import("./pages/PaintingsDetail.vue");
const About = () => import("./pages/About.vue");
const Services = () => import("./pages/Services.vue");
const Contact = () => import("./pages/Contact.vue");
const Document = () => import("./pages/Document.vue");

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            name: "home",
            path: "/",
            component: Home,
        },
        {
            name: "document",
            path: "/document/:id",
            props: true,
            component: Document,
        },
        {
            name: "design",
            path: "/design",
            component: Design,
            meta: {
                page: "design",
            },
        },
        {
            name: "design-detail",
            path: "/design/detail/:link",
            component: DesignDetail,
            props: true,
            meta: {
                page: "design",
            },
        },
        {
            name: "paintings",
            path: "/paintings",
            component: Paintings,
            meta: {
                page: "paintings",
            },
        },
        {
            name: "paintings-detail",
            path: "/paintings/detail/:link",
            component: PaintingsDetail,
            props: true,
            meta: {
                page: "paintings",
            },
        },
        {
            name: "servicesDesign",
            path: "/design/services",
            component: Services,
            meta: {
                page: "design",
            },
        },
        {
            name: "aboutPaintings",
            path: "/paintings/about",
            component: About,
            meta: {
                page: "paintings",
            },
        },
        {
            name: "contactDesign",
            path: "/design/contact",
            component: Contact,
            meta: {
                page: "design",
            },
        },
        {
            name: "contactPaintings",
            path: "/paintings/contact",
            component: Contact,
            meta: {
                page: "paintings",
            },
        },
        { name: "notFound", path: "/:notFound(.*)", component: NotFound },
    ],
    linkActiveClass: "active",
    scrollBehavior(_to, _from, savedPosition) {
        return new Promise((resolve, _reject) => {
            setTimeout(() => {
                if (savedPosition) {
                    resolve(savedPosition);
                } else {
                    resolve({ left: 0, top: 0 });
                }
            }, 700);
        });
    },
});

router.beforeEach((to, _from, next) => {
    store.dispatch("resetFetchError");
    store.dispatch("setBurgerMenu", { value: false });
    if (to.meta.page) {
        store.dispatch("setEnterPage", { value: to.meta.page });
    }
    if (to.name) {
        NProgress.start();
    }
    next();
});

router.afterEach((_, __) => {
    NProgress.done();
});

export default router;
