<template>
    <button type="button" class="button" :class="{ 'button--no-wrap': noWrap }">
        <slot></slot>
    </button>
</template>

<script>
export default {
    props: ["noWrap"],
};
</script>

<style lang="scss">
@import "../../assets/sass/variables.scss";

.button {
    display: inline-block;
    color: $text-color;
    background: $secondary-color;
    border-radius: 0;
    border: none;
    box-shadow: none;
    outline: none;
    padding: 0.9rem 2.5rem;
    appearance: none;
    cursor: pointer;
    text-transform: uppercase;
    transition: background-color 0.3s;
    &:hover,
    &:focus {
        color: $text-color;
        background: $primary-color;
    }
    &--no-wrap {
        white-space: nowrap;
    }
}
</style>
