export default {
    SET_ENTER_PAGE(state, payload) {
        state.enterPage = payload.value;
    },
    SET_VISIBLE_LIGHTBOX(state, payload) {
        state.visibleLightbox = payload;
    },
    SET_BURGER_MENU(state, payload) {
        if (payload) {
            state.burgerMenu = payload.value;
        } else {
            state.burgerMenu = !state.burgerMenu;
        }
    },
    SET_COOKIE_ACCEPT(state) {
        state.cookieAccept = true;
    },
    UNSET_COOKIE_ACCEPT(state) {
        state.cookieAccept = false;
    },
    SET_CONTACT_PAGE(state, payload) {
        state.contactPage = payload.data;
    },
    SET_ABOUT_PAGE(state, payload) {
        state.aboutPage = payload;
    },
    SET_SERVICES_PAGE(state, payload) {
        state.servicesPage = payload;
    },
    SET_PAINTINGS_PAGE(state, payload) {
        state.paintingsPage = payload;
    },
    SET_PAINTINGS_DETAIL(state, payload) {
        state.paintingsDetail = payload;
    },
    SET_DESIGN_PAGE(state, payload) {
        state.designPage = payload;
    },
    SET_DESIGN_DETAIL(state, payload) {
        state.designDetail = payload;
    },
    SET_SOCIAL_ICONS(state, payload) {
        state.socialIcons = payload;
    },
    SET_FETCH_ERROR(state, payload) {
        state.fetchError = payload;
    },
};
