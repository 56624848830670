<template>
    <div>
        <p class="h3">
            Error {{ response.status }}<br />
            {{ response.statusText }}
        </p>
        <base-button @click="tryAgain">Try again</base-button>
    </div>
</template>

<script>
import BaseButton from "./BaseButton.vue";

export default {
    components: { BaseButton },
    props: {
        tryAgain: Function,
        response: Object,
    },
};
</script>

<style lang="scss" scoped>
div {
    text-align: center;
}

p {
    margin-bottom: 1rem;
}
</style>
