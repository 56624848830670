<template>
    <content>
        <Head v-if="meta.show">
            <title>{{ generateMetaTitle }}</title>
        </Head>
        <container>
            <div>
                <h1 class="h1">Not found</h1>
                <div class="buttons">
                    <router-link :to="{ name: 'design' }" class="button"
                        >Design</router-link
                    >
                    <router-link :to="{ name: 'paintings' }" class="button"
                        >Paintings</router-link
                    >
                </div>
            </div>
        </container>
    </content>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            meta: { show: true, title: "Not found" },
        };
    },
    computed: {
        ...mapGetters(["getAppName", "getSeparator"]),
        generateMetaTitle() {
            return this.meta.title + this.getSeparator + this.getAppName;
        },
    },
    deactivated() {
        this.meta.show = false;
    },
    activated() {
        this.meta.show = true;
    },
};
</script>

<style lang="scss" scoped>
div {
    text-align: center;
}
.buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.button {
    margin: 1rem;
    width: 100%;
    max-width: 12rem;
}
</style>
