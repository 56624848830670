<template>
    <footer>
        <div class="footer">
            <container>
                <div class="footer__top">
                    <transition name="fade" mode="out-in">
                        <footer-social
                            :data="data"
                            v-if="!isLoading"
                        ></footer-social>
                    </transition>
                </div>
                <div class="footer__bottom">
                    <span>©{{ currentYear }}, WekoWorks</span>
                    <span
                        >Created by
                        <a
                            href="https://www.onlima.sk"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="onlima"
                            >Onlima.sk</a
                        ></span
                    >
                </div>
            </container>
        </div>
    </footer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FooterSocial from "./FooterSocial.vue";

export default {
    components: {
        FooterSocial,
    },
    data() {
        return {
            isLoading: false,
            data: {},
        };
    },
    computed: {
        currentYear() {
            return new Date().getFullYear();
        },
        ...mapGetters(["getSocialIcons"]),
    },
    methods: {
        ...mapActions(["fetchSocialIcons"]),
        async loadSocialData() {
            this.isLoading = true;
            const allData = await this.fetchSocialIcons();
            this.isLoading = false;
            this.data = this.getSocialIcons;
        },
    },
    created() {
        this.loadSocialData();
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/sass/variables.scss";
.footer {
    display: block;
    width: 100%;
    background: $base-color-light;
    &__top {
        min-height: 5.188rem;
        margin-bottom: -2rem;
    }
    &__bottom {
        padding: 0.5rem 0 1.6rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $text-color-dark;
        margin-top: 1rem;
        line-height: 1.4;
        @media (min-width: 575px) {
            flex-direction: row;
            justify-content: space-between;
            margin-top: 0;
        }
    }

    .onlima {
        text-decoration: none;
        color: $text-color;
        transition: color 0.3s;
        &:hover {
            color: $primary-color;
        }
    }
}
</style>
