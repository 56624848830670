import { createStore } from "vuex";

import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

const store = createStore({
    state() {
        return {
            appName: "Wekoworks",
            separator: " | ",
            enterPage: null,
            visibleLightbox: false,
            burgerMenu: false,
            cookieAccept: false,
            socialIcons: {},
            contactPage: {},
            aboutPage: {},
            servicesPage: {},
            paintingsPage: {},
            paintingsDetail: {},
            designPage: {},
            designDetail: {},
            fetchError: null,
        };
    },
    mutations: mutations,
    actions: actions,
    getters: getters,
});

export default store;
