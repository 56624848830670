<template>
    <ul>
        <li v-for="item in data" :key="item.title">
            <a :href="item.link" target="_blank" rel="noopener noreferrer">
                <img :src="item.img" />
                <span>{{ item.title }}</span>
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        data: Array,
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/sass/variables.scss";

ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    transform: translateY(-2rem);
}
a {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $base-color-light;
    color: $text-color;
    text-decoration: none;
    width: 7rem;
    padding: 1rem;
    margin: 0 0.4rem;
    z-index: 1;
    transition: background 0.3s;
    &:hover {
        background: $primary-color;
        color: $text-color;
    }
}
img {
    width: 1.875rem;
    height: 1.875rem;
    object-fit: contain;
    margin-bottom: 0.5rem;
}
span {
    font-family: $title-font;
    font-size: 0.75rem;
}
</style>
