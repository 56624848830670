<template>
    <img
        :data-src="src"
        :class="[{ loading: loading }, classList]"
        :alt="alt"
    />
</template>

<script>
export default {
    props: {
        src: String,
        classList: String | Object,
        alt: String,
    },
    data() {
        return {
            loading: true,
        };
    },
    mounted() {
        const { $el } = this;
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                const src = $el.getAttribute("data-src");
                $el.src = src;
                $el.onload = () => {
                    this.loading = false;
                };
                observer.disconnect();
            }
        });
        observer.observe($el);
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/sass/variables.scss";

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

img {
    &.loading {
        animation-duration: 1.25s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: $secondary-color;
        background: linear-gradient(
            to right,
            $base-color-light 10%,
            $base-color 18%,
            $base-color-light 33%
        );
        background-size: 1000px 180px;
    }
}
</style>
