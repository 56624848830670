<template>
    <div class="content">
        <slot></slot>
    </div>
</template>

<style lang="scss" scoped>
@import "../../assets/sass/variables.scss";

.content {
    padding: calc(#{$header-height} + 2rem) 0;
}
</style>
