<template>
    <div id="app-wrapper" :class="{ blurry: getVisibleLightbox }">
        <transition name="header" mode="out-in">
            <keep-alive>
                <the-header v-if="!isHome"></the-header>
            </keep-alive>
        </transition>
        <main>
            <router-view v-slot="{ Component }">
                <transition name="page" mode="out-in">
                    <keep-alive max="5">
                        <component :is="Component" :key="$route.path" />
                    </keep-alive>
                </transition>
            </router-view>
        </main>
        <transition name="footer" mode="out-in">
            <keep-alive>
                <the-footer v-if="!isHome"></the-footer>
            </keep-alive>
        </transition>
        <teleport to="body">
            <transition name="cookie" mode="out-in">
                <cookie-bar v-if="!getCookieAccept"></cookie-bar>
            </transition>
        </teleport>
    </div>
</template>

<script>
import TheHeader from "./components/layout/TheHeader.vue";
import TheFooter from "./components/layout/TheFooter.vue";
import CookieBar from "./components/cookiebar/CookieBar.vue";
import { mapGetters } from "vuex";
import vhCheck from "vh-check";

export default {
    components: {
        TheHeader,
        TheFooter,
        CookieBar,
    },
    computed: {
        isHome() {
            return this.$route.name === "home";
        },
        ...mapGetters(["getVisibleLightbox", "getCookieAccept"]),
    },
    beforeCreate() {
        if (localStorage.getItem("cookieBar")) {
            if (+localStorage.getItem("cookieBar") < new Date().getTime()) {
                this.$store.dispatch("unsetCookieAccept");
            } else {
                this.$store.dispatch("setCookieAccept");
            }
        }
    },
    mounted() {
        vhCheck();
    },
};
</script>

<style lang="scss">
@import "./assets/sass/global.scss";

#app-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: calc(100vh - var(--vh-offset, 0px));
    overflow: hidden;
    transition: filter 0.3s;
    &.blurry {
        filter: blur(10px);
    }
}

.header-enter-active,
.header-leave-active {
    transition: transform 0.6s ease-in-out, opacity 0.6s;
}

.header-enter-active {
    transition-delay: 0.6s;
}

.header-enter-from,
.header-leave-to {
    transform: translateY(-100%);
    opacity: 0;
}

.header-leave-to {
    transition-delay: 0.3s;
}

.page-enter-active,
.page-leave-active {
    transition: transform 0.6s, opacity 0.6s ease-in-out;
}

.page-enter-from {
    opacity: 0;
    transform: translateY(-10px);
}
.page-leave-to {
    opacity: 0;
    transform: translateY(10px);
}

.footer-enter-active,
.footer-leave-active {
    transition: transform 0.6s ease-in-out, opacity 0.6s;
}

.footer-enter-active {
    transition-delay: 0.6s;
}
.footer-enter-from,
.footer-leave-to {
    transform: translateY(100%);
    opacity: 0;
}

.cookie-enter-active,
.cookie-leave-active {
    transition: transform 0.6s;
}

.cookie-enter-from,
.cookie-leave-to {
    transform: translateY(100%);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
