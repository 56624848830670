import axios from "axios";

export default {
    setEnterPage(context, payload) {
        context.commit("SET_ENTER_PAGE", payload);
    },
    setVisibleLightbox(context, payload) {
        context.commit("SET_VISIBLE_LIGHTBOX", payload);
    },
    setBurgerMenu(context, payload) {
        context.commit("SET_BURGER_MENU", payload);
    },
    setCookieAccept(context) {
        context.commit("SET_COOKIE_ACCEPT");

        if (!localStorage.getItem("cookieBar")) {
            let expiration = new Date();
            expiration.setDate(expiration.getDate() + 30);
            localStorage.setItem("cookieBar", expiration.getTime());
        }
    },
    unsetCookieAccept(context) {
        context.commit("UNSET_COOKIE_ACCEPT");

        if (!localStorage.getItem("cookieBar")) {
            localStorage.removeItem("cookieBar");
        }
    },
    setFetchError(context) {
        context.commit("SET_FETCH_ERROR", context);
    },
    resetFetchError(context) {
        context.commit("SET_FETCH_ERROR", null);
    },
    async fetchPageData(context, payload) {
        let apiURL;

        if (payload.page === "detail") {
            apiURL = `/api/${payload.apiEnterPage}/${payload.page}/${payload.link}`;
        } else {
            apiURL = `/api/${payload.apiEnterPage}/${payload.page}`;
        }

        try {
            const response = await axios.get(apiURL);
            const responseData = response.data;

            if (payload.page === "contact") {
                context.commit("SET_CONTACT_PAGE", responseData);
            } else if (payload.page === "about") {
                context.commit("SET_ABOUT_PAGE", responseData);
            } else if (payload.page === "services") {
                context.commit("SET_SERVICES_PAGE", responseData);
            } else if (payload.page === "grid") {
                if (this.state.enterPage === "design") {
                    context.commit("SET_DESIGN_PAGE", responseData);
                } else {
                    context.commit("SET_PAINTINGS_PAGE", responseData);
                }
            } else if (payload.page === "detail") {
                if (this.state.enterPage === "design") {
                    context.commit("SET_DESIGN_DETAIL", responseData);
                } else {
                    context.commit("SET_PAINTINGS_DETAIL", responseData);
                }
            }
        } catch (error) {
            context.commit("SET_FETCH_ERROR", error.response);
        }
    },
    async fetchSocialIcons(context, payload) {
        try {
            const response = await axios.get("/api/settings/socials/list");
            const responseData = response.data;
            context.commit("SET_SOCIAL_ICONS", responseData.data);
        } catch (error) {
            console.log(error);
        }
    },
};
