<template>
    <header class="header">
        <container>
            <div class="header__inner">
                <router-link :to="{ name: 'home' }">
                    <img
                        src="/img/logo/wekoworks-logo-full.svg"
                        alt="WekoWorks"
                    />
                </router-link>
                <button
                    type="button"
                    @click="setBurgerMenu({ value: !getBurgerMenu })"
                >
                    <span>Menu</span>
                    <img src="/img/icons/menu.svg" />
                </button>
                <nav :class="{ show: getBurgerMenu }">
                    <button
                        type="button"
                        @click="setBurgerMenu({ value: !getBurgerMenu })"
                    >
                        <img src="/img/icons/close.svg" />
                    </button>
                    <transition name="nav" mode="out-in">
                        <ul v-if="getEnterPage === 'design'">
                            <li>
                                <router-link :to="{ name: 'design' }"
                                    >Design</router-link
                                >
                            </li>
                            <li>
                                <router-link :to="{ name: 'servicesDesign' }"
                                    >Services</router-link
                                >
                            </li>
                            <li>
                                <router-link :to="{ name: 'contactDesign' }"
                                    >Contact</router-link
                                >
                            </li>
                        </ul>
                        <ul v-else-if="getEnterPage === 'paintings'">
                            <li>
                                <router-link :to="{ name: 'paintings' }"
                                    >Paintings</router-link
                                >
                            </li>
                            <li>
                                <router-link :to="{ name: 'aboutPaintings' }"
                                    >About</router-link
                                >
                            </li>
                            <li>
                                <router-link :to="{ name: 'contactPaintings' }"
                                    >Contact</router-link
                                >
                            </li>
                        </ul>
                        <ul v-else>
                            <li>
                                <router-link :to="{ name: 'paintings' }"
                                    >Paintings</router-link
                                >
                            </li>
                            <li>
                                <router-link :to="{ name: 'design' }"
                                    >Design</router-link
                                >
                            </li>
                        </ul>
                    </transition>
                </nav>
            </div>
        </container>
    </header>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    computed: {
        ...mapGetters(["getEnterPage", "getBurgerMenu"]),
    },
    methods: {
        ...mapActions(["setBurgerMenu"]),
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/sass/variables.scss";

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    background: $base-color;
    &__inner {
        display: flex;
        width: 100%;
        height: $header-height;
        align-items: center;
        justify-content: space-between;
    }
}
img {
    max-width: 100%;
    height: auto;
    width: 11.75rem;
}
nav {
    @media (max-width: 767px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $base-color;
        transform: translateX(100%);
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        z-index: 2;
        &.show {
            transform: translateX(0);
            opacity: 1;

            li {
                opacity: 1;
                transform: translateY(0);
                &:nth-child(1) {
                    transition-delay: 0.3s;
                }
                &:nth-child(2) {
                    transition-delay: 0.4s;
                }
                &:nth-child(3) {
                    transition-delay: 0.5s;
                }
            }
        }
        li {
            transform: translateY(-10px);
            opacity: 0;
            transition: all 0.3s;
        }
    }
    button {
        position: absolute;
        top: 1.4rem;
        right: 1.2rem;
        img {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    font-size: 1rem;
    font-family: $base-font;
    @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
        height: 100%;
        overflow-y: auto;
        font-size: 1.6rem;
        li:first-child {
            margin-top: auto;
        }
        li:last-child {
            margin-bottom: auto;
        }
    }
    li {
        @media (min-width: 768px) {
            margin-left: 2.5rem;
        }
    }
    a {
        display: block;
        color: #fff;
        text-decoration: none;
        transition: color 0.3s;
        &:hover,
        &:active,
        &.active {
            color: $primary-color;
        }
        @media (max-width: 767px) {
            padding: 1.3rem 1rem;
        }
    }
}

button {
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
        width: 2rem;
        height: 2rem;
        object-fit: contain;
    }
    @media (min-width: 768px) {
        display: none;
    }
    span {
        display: inline-block;
        color: $text-color;
        text-transform: uppercase;
        margin-right: 1rem;
        font-size: 1.1rem;
        font-family: $title-font;
    }
}

.nav-enter-active,
.nav-leave-active {
    transition: opacity 0.6s;
}

.nav-enter-active {
    transition-delay: 0.6s;
}

.nav-enter-from,
.nav-leave-to {
    opacity: 0;
}
</style>
