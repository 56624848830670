export default {
    getEnterPage(state) {
        return state.enterPage;
    },
    getVisibleLightbox(state) {
        return state.visibleLightbox;
    },
    getBurgerMenu(state) {
        return state.burgerMenu;
    },
    getAppName(state) {
        return state.appName;
    },
    getSeparator(state) {
        return state.separator;
    },
    getCookieAccept(state) {
        return state.cookieAccept;
    },
    getSocialIcons(state) {
        return state.socialIcons;
    },
    getContactPage(state) {
        return state.contactPage;
    },
    getAboutPage(state) {
        return state.aboutPage;
    },
    getServicesPage(state) {
        return state.servicesPage;
    },
    getPaintingsPage(state) {
        return state.paintingsPage;
    },
    getPaintingsDetail(state) {
        return state.paintingsDetail;
    },
    getDesignPage(state) {
        return state.designPage;
    },
    getDesignDetail(state) {
        return state.designDetail;
    },
    getFetchError(state) {
        return state.fetchError;
    },
};
