<template>
    <div>
        <img src="/img/icons/loader.svg" class="loader" />
    </div>
</template>

<style lang="scss" scoped>
.loader {
    max-width: 100%;
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}
</style>
