// Vue
import { createApp } from "vue";
// Vue plugins
import router from "./router.js";
import store from "./store/index.js";
import head from "./head.js";
import VueEasyLightbox from "vue-easy-lightbox";
import AOS from "aos";
import "aos/dist/aos.css";
// App init
import App from "./App.vue";
// Base components
import Container from "./components/UI/Container.vue";
import Content from "./components/UI/Content.vue";
import Loader from "./components/UI/Loader.vue";
import Error from "./components/UI/Error.vue";
import ImgLazy from "./components/imglazy/ImgLazy.vue";
// Third party components
import { Head } from "@egoist/vue-head";

const app = createApp(App);

app.component("Container", Container);
app.component("Content", Content);
app.component("Loader", Loader);
app.component("Error", Error);
app.component("ImgLazy", ImgLazy);
app.component("Head", Head);

app.use(router);
app.use(store);
app.use(head);
app.use(VueEasyLightbox);
app.use(
    AOS.init({
        duration: 800,
        once: true,
        offset: 200,
    })
);

// Disalbe start animation on page load
router.isReady().then(function () {
    app.mount("#app");
});
