<template>
    <div
        class="container"
        :class="{ 'container--sm': small, 'container--md': medium }"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        small: Boolean,
        medium: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.container {
    max-width: 1806px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    &--sm {
        max-width: 100%;
        @media (min-width: 768px) {
            max-width: calc(730px + 2rem);
        }
        @media (min-width: 992px) {
            max-width: calc(850px + 2rem);
        }
        @media (min-width: 1236px) {
            max-width: calc(998px + 2rem);
        }
    }
    &--md {
        @media (min-width: 768px) {
            max-width: calc(730px + 2rem);
        }
        @media (min-width: 992px) {
            max-width: calc(950px + 2rem);
        }
        @media (min-width: 1236px) {
            max-width: calc(1200px + 2rem);
        }
    }
}
</style>
